import React from 'react'
import Layout from '../components/layout/Layout'
import { graphql } from 'gatsby';
import Img from 'gatsby-image'
import SEO from '../components/SEO';
import ButtonElement from '../components/contentful-elements/ButtonElement';
export default function ThankYou(props) {
    const image = props.data?.backgroundImage
    return (
        <Layout
            className="-mb-24"
        >
            <SEO title="Thank you" />

            <div className="w-full min-h-screen relative flex z-0 -mt-12 md:mt-0 ">
                
                <div className="w-full h-full absolute">
                    <Img fluid={image.fluid} className="w-full h-full" />
                </div>

                <div className="relative  flex flex-col  justify-center text-center w-full px-4 space-y-4 ">
                    <h3 className="text-3xl md:text-5xl text-white">Thanks for your email!</h3>
                    <p className="text-white text-xl md:text-2xl ">We have received your support request and <br className="hidden md:block" /> someone from our team will be in touch soon.</p>
                <div className="inline-block w-56 mx-auto">
                <ButtonElement text="Take me home" alt link="/" className="mx-auto mt-4" />
                </div>
                  
                </div>
            </div>

        </Layout>
    )
}


export const ThankYouQuery = graphql`
query ThankYouQuery {
  backgroundImage: contentfulAsset(contentful_id: {eq: "6tQICzcU2oRPzdKHy23Gw5"}) {
    ...GatsbyFluidImageFragment
    title
  }
}
`